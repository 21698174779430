import { ActionTree, GetterTree, MutationTree } from "vuex";
import moment from "moment";
import Vue from "vue";
import { AccountState } from "../store_types/account.types";
import { RootState } from "../store_types/index.types";
import UserAddress from "../models/UserAddress";
import { cloneDeep } from "~/util/clone";
import { ACCOUNT_TABS } from "~/util/types";
import { validateUserData } from "~/util/data-validation";
import { InfoPages, OrderHistory } from "~/util/api.types";
import localStorageHelper from "~/service/local-storage-helper/index";
import { getAddressQuery } from "~/service/api/helpers";
import { DEFAULT_COOKIES_OPTIONS } from "~/service/cookies-helper";
import { sendYandexMetric } from "~/service/ecommerce";

const CANCEL_ORDER_ID = 40;

export const state: () => AccountState = () => ({
    // tab: ACCOUNT_TABS.CURRENT_ORDERS,
    current_orders: [],
    orders_history: [],
    profile: null,
    profileLastUpdate: null,
    // cities: [],
    addresses: [],

    history_limit: 10,
    history_offset: 0,
    history_over: false,
    history_pending: false,

    profile_changed: false,
    profile_saved_message: "",
    profile_saved_success: false,
    addressEditId: null,
    showMobileAppBanner: true,
    order_statuses: [],
    historyAddressId: null,
    anotherAddress: {},
    bonusDefaultPercent: 0,
    videoStatusInit: false,
    mysteryShopperOffer: null,
    isPasswordRecoveryLink: false,
});

export const getters: GetterTree<AccountState, RootState> = {
    currentOrders: (state) => {
        return state.current_orders;
    },
    cards: (state, getters, rootState, rootGetters) => {
        return state.profile && state.profile.cards ? state.profile.cards : [];
    },
    lastOrder: (state) => {
        return state.current_orders?.[0];
    },
    defaultCardId: (state, getters, rootState, rootGetters) => {
        const defaultCard = getters.cards.find((o) => o.default == true);

        return defaultCard ? defaultCard.id : null;
    },
    // favoriteProducts: (state) => {
    //     return state.profile && state.profile.favorite_products ? state.profile.favorite_products : [];
    // },
    ordersHistory: (state) => {
        return state.orders_history.filter((o) => o.complete == true);
    },
    getHistoryOver: (state) => {
        return state.history_over;
    },
    getHistoryPending: (state) => {
        return state.history_pending;
    },
    bonusCard: (state) => {
        return !!state.profile && !!state.profile.bonus_card ? state.profile.bonus_card : null;
    },
    profile: (state) => {
        return state.profile;
    },
    bonusPoints: (state, getters) => {
        return getters.bonusCard ? getters.bonusCard.bonuses : 0;
    },
    userName: (state, getters) => {
        return getters.profile ? getters.profile.name : "";
    },
    userAddresses: (state) => {
        return state.addresses;
    },
    addresses: (state) => {
        return state.addresses.map(function (address) {
            return new UserAddress(address);
        });
    },
    historyAddress: (state) => {
        if (!state.historyAddressId || state.historyAddressId === "another") {
            return undefined;
        }
        return (state.addresses || []).find((a) => a.id == state.historyAddressId);
    },
    hasCurrentOrders: (state, rootState) => {
        return (
            (state.profile && state.profile.flags && state.profile.flags.has_current_orders) ||
            state.current_orders.length > 0 ||
            (rootState.order && rootState.order.lastOrder)
        );
    },
    hasHistoryOrders: (state) => {
        return state.profile && state.profile.flags && state.profile.flags.has_history_orders; // || state.orders_history.length > 0;
    },
    unratedOrder: (state) => {
        return state.profile && state.profile.flags && state.profile.flags.unrated_order
            ? state.profile.flags.unrated_order
            : null;
    },
    cacheExpired: (state) => {
        return (
            !state.profile || !state.profileLastUpdate || (new Date().getTime() - state.profileLastUpdate) / 1000 >= 20
        );
    },
    AllCompensations: (state) => {
        return state.profile && state.profile.compensations ? state.profile.compensations : [];
    },
    isShowTotalBonusGained(state, getters, rootState) {
        return !!getters.bonusCard || (rootState.form.phone && rootState.form.hasBonusCard[rootState.form.phone]);
    },
    hasAnotherAddress: (state) => {
        if (!state.anotherAddress) {
            return false;
        }

        const withoutTouched = !state.anotherAddress.touched && Object.keys(state.anotherAddress).length > 0;
        const withTouched = state.anotherAddress.touched && Object.keys(state.anotherAddress).length > 1;

        return state.anotherAddress && (withoutTouched || withTouched);
    },
    findBetOfferOrder(state, getters) {
        return getters.currentOrders.find((order) => (order.bet_offer ? order : null)) || null;
    },
    expectedDeliveryTime: (state, getters) => {
        return getters.currentOrders[getters.currentOrders.length - 1]?.delivery_info?.expected_time;
    },
    isPasswordRecoveryLink(state) {
        return state.isPasswordRecoveryLink;
    },
    // isProductFavorite: (getters, rootGetters) => (id) => {
    //     if (rootGetters['auth/isLoggedIn']) {
    //         return getters.favoriteProducts.find(o => o == id) != undefined;
    //     }
    //     return false
    // }
};

export const mutations: MutationTree<AccountState> = {
    // setTab (state, tab) {
    //     state.tab = tab;
    // },
    setHistoryLimit(state, limit) {
        state.history_limit = limit;
    },
    setHistoryOffset(state, offset) {
        state.history_offset = offset;
    },
    setHistoryOver(state, val) {
        state.history_over = val;
    },
    setHistoryPending(state, val) {
        state.history_pending = val;
    },
    setCurrentOrders(state, current_orders) {
        state.current_orders = current_orders.filter((order) => order.status?.id !== CANCEL_ORDER_ID);
    },
    setOrdersHistory(state, orders_history) {
        if (state.history_offset > 0) {
            state.orders_history = state.orders_history.concat(orders_history);
        } else {
            state.orders_history = orders_history;
        }
    },
    // setCities (state, tab) {
    //     state.cities = tab;
    // },
    setOrdersDetails(state, data) {
        state.orders_history = state.orders_history.map((order) => {
            if (order.id == data.order_id) {
                return Object.assign({}, order, data.details);
            } else {
                return order;
            }
        });
    },
    setProfile(state, profile) {
        state.profile = profile;
    },
    // setFavoriteProduct(state, products){
    //     state.profile.favorite_products = products;
    // },
    updateName(state, name) {
        if (!state.profile) {
            return;
        }
        state.profile.name = name;
    },
    updateEmail(state, email) {
        if (!state.profile) {
            return;
        }
        state.profile.email = email;
    },
    setAddresses(state, addresses) {
        state.addresses = addresses;
    },
    addAddress(state, data) {
        const address = data.address;
        const addressId = data.address_id;

        address.id = addressId;

        state.addresses.push(address);
    },
    deleteAddress(state, addressId) {
        state.addresses = state.addresses.filter((addr) => addr.id != addressId);
    },
    setHistoryAddress(state, id) {
        state.historyAddressId = id;

        if (id === "another") {
            state.anotherAddress.touched = moment().unix();
            return;
        }

        const addressIndex = state.addresses.findIndex((a) => a.id == id);
        if (addressIndex < 0) {
            return;
        }

        const address = state.addresses[addressIndex];
        address.touched = moment().unix();

        Vue.set(state.addresses, addressIndex, address);
    },
    clearData(state) {
        state.current_orders = [];
        state.orders_history = [];
        state.profile = null;
        state.addresses = [];
    },
    setProfileChanged(state, value) {
        state.profile_changed = !!value;
    },
    setProfileSavedSuccess(state, value) {
        state.profile_saved_success = !!value;

        if (state.profile_saved_success === true) {
            state.profile_saved_message = "Данные сохранены";
        } else {
            state.profile_saved_message = "";
        }
    },
    setProfileSmsSubscription(state, value) {
        if (!state.profile) {
            return;
        }

        state.profile.subscribe_sms = !!value;
    },
    setProfileEmailSubscription(state, value) {
        if (!state.profile) {
            return;
        }
        state.profile.subscribe_email = !!value;
    },
    setLastUpdated(state, val: true | null) {
        state.profileLastUpdate = val ? new Date().getTime() : null;
    },
    setEditAddressId(state, addressId) {
        state.addressEditId = addressId;
    },
    updateAddress(state, addressData) {
        const address = state.addresses.find((d) => d.id == addressData.id);
        if (!address) {
            return;
        }

        for (const k in addressData) {
            address[k] = addressData[k];
        }
    },
    setOrderStatuses(state, statuses) {
        state.order_statuses = statuses;
    },
    setBonusDefaultPercent(state, value) {
        state.bonusDefaultPercent = parseInt(value) || 0;
    },
    setAnotherAddress(state, value) {
        state.anotherAddress = value;
        state.anotherAddress.touched = moment().unix();
    },
    clearAnotherAddress(state) {
        state.anotherAddress = {};
    },
    setVideoStatusInit(state, value) {
        state.videoStatusInit = value;
    },
    setMysteryShopperOffer(state, offer) {
        state.mysteryShopperOffer = offer;
    },
    deleteBetOffer(state: AccountState, id: number) {
        const order: OrderHistory | undefined = state.current_orders.find((order) => {
            return order.bet_offer && order.bet_offer.length > 0 && order.id == id;
        });

        if (order) {
            delete order.bet_offer;
        }
    },
    changeMobileBanner(state, isShow) {
        state.showMobileAppBanner = isShow;
    },
    setDefaultCardId(state, cardId) {
        if (!state.profile) {
            return;
        }
        const cards = [...state.profile.cards];
        cards.forEach((o) => {
            o.default = o.id == cardId;
        });
        state.profile.cards = cards;
    },
    deleteCardToken(state, cardId) {
        if (!state.profile) {
            return;
        }
        const cards = state.profile.cards.filter((o) => o.id != cardId);
        state.profile.cards = cards;
    },
    setPasswordRecoveryLink(state, value) {
        state.isPasswordRecoveryLink = value;
    },
};

export const actions: ActionTree<AccountState, RootState> = {
    // init({dispatch, state, rootState}) {
    //     dispatch("gotoTab", rootState.route.name || state.tab)
    // },
    setAppBanner({ commit }, isShow) {
        this.$cookies.set("appBanner", isShow, DEFAULT_COOKIES_OPTIONS);
        commit("changeMobileBanner", isShow);
    },
    initHistory({ commit, dispatch }) {
        commit("setHistoryPending", false);
        commit("setHistoryOver", false);
        commit("setHistoryLimit", 20);
        commit("setHistoryOffset", 0);
        dispatch("fetchOrdersHistory");
    },
    // gotoTab({commit}, tab) {
    //     commit("setTab", tab);
    // },
    async redirectTo({ commit }, tab: string | null = null) {
        let path = "/user/profile";

        if (tab === ACCOUNT_TABS.CURRENT_ORDERS) {
            path = "/user/current_orders";
        }

        if (tab === ACCOUNT_TABS.ORDERS_HISTORY) {
            path = "/user/orders-history";
        }
        await this.$router.pushAsync(path).catch((err) => console.error(err, "Непредсказуемое поведение роутинга"));
    },
    setCurrentOrders({ commit }, orders) {
        commit("setCurrentOrders", orders);
    },
    async fetchCurrentOrders({ dispatch }) {
        const orders: any[] = [];

        try {
            const resp = await this.$api.get("/orders/current");

            // апи возвращает один последний заказ
            if (!Array.isArray(resp)) {
                orders.push(resp);
            }
            dispatch("cart/sendEcommerce", resp, { root: true });
        } catch (err) {
            // orders = [];
        }

        await dispatch("setCurrentOrders", orders);
        dispatch("fetchOrdersStatuses", orders);
    },
    async fetchOrdersStatuses({ commit }, currentOrders) {
        // fetch statuses if only one current order
        if (currentOrders[0]) {
            await this.$api.get("/orders/statuses", { order_id: currentOrders[0].id }).then((resp) => {
                commit("setOrderStatuses", resp);
            });
        }
    },
    async fetchOrdersHistory({ commit, dispatch, state }) {
        commit("setHistoryPending", true);

        try {
            if (state.history_over) {
                return;
            }

            dispatch("wait/start", "account.orders_history", { root: true });
            const resp = await this.$api.get("/orders/list", {
                max_records: state.history_limit,
                offset: state.history_offset,
            });

            commit("setOrdersHistory", resp.orders);
            commit("setHistoryOffset", state.history_offset + state.history_limit);

            if (resp.orders.length == 0 || resp.orders.length < state.history_limit) {
                commit("setHistoryOver", true);
            }
        } catch (error) {
            console.warn("Ошибка получения истории заказа");
            console.error(error);
            // return Promise.reject(err);
        } finally {
            commit("setHistoryPending", false);
            dispatch("wait/end", "account.orders_history", { root: true });
        }
    },
    async fetchOrderDetails({ commit, dispatch }, order_id) {
        dispatch("wait/start", "account.orders_history" + order_id, { root: true });

        try {
            const resp = await this.$api.get("/orders/details/" + order_id, { show_components: true });
            commit("setOrdersDetails", { order_id, details: resp });
        } catch (err) {
            //
        } finally {
            dispatch("wait/end", "account.orders_history" + order_id, { root: true });
        }
    },
    async fetchProfile({ getters, commit, dispatch, rootGetters }) {
        /* if(!getters.cacheExpired) {
            return;
        } */

        if (!rootGetters["auth/isLoggedIn"]) {
            return;
        }

        commit("setLastUpdated", true);

        try {
            const resp = await this.$api.get("/user/profile");
            // Привяжем id из су к яндекс метрике
            sendYandexMetric(
                null,
                {
                    name: resp.name,
                    sex: resp.sex,
                    UserID: resp.profile_id,
                },
                "userParams"
            );

            commit("setProfile", resp);
            commit("setBonusDefaultPercent", resp && resp.default_bonus_percent ? resp.default_bonus_percent : 0);
        } catch (error) {
            console.warn("Ошибка получения профиля");
            console.error(error);

            if (typeof error === "object" && error.error_code == "no_user") {
                await dispatch("auth/logout", null, { root: true });
            }
            return Promise.reject(error);
        }

        await dispatch("loadAddresses");
    },
    async updateProfile({ commit, state }, profileData) {
        commit("setProfileChanged", false);

        const data = cloneDeep(profileData || state.profile);
        if (data.flags !== undefined) {
            delete data.flags;
        }

        const resp = await this.$api.post("/user/profile", data);

        if (resp.success) {
            commit("setProfile", resp.user_info);
            commit("setProfileSavedSuccess", true);
        } else {
            alert("Ошибка сохранения");
            commit("setProfileChanged", true);
            commit("setProfileSavedSuccess", false);
        }
    },
    async loadAddresses({ commit, dispatch, rootState }) {
        try {
            const params = getAddressQuery(rootState.delivery.city?.id, false);
            const resp = await this.$api.get("address/index", params);
            if (resp.success !== true) {
                console.warn("Load addresses failed: success != true");
            }

            commit("setAddresses", resp.data);
            dispatch("delivery/findHistoryAddressId", null, { root: true });
        } catch (error) {
            console.warn("Load addresses failed: caught exception");
            console.error(error);
        }
    },
    async addAddress({ commit, rootState }, address) {
        try {
            const resp = await this.$api.post(
                "address/add?" + getAddressQuery(rootState.delivery.city?.id, true),
                address
            );
            if (resp.success === true) {
                commit("addAddress", { address, address_id: resp.address_id });
            }
        } catch (error) {
            console.warn("Ошибка добавления адреса");
            console.error(error);
            return Promise.reject({ [error.error_code]: error.error_message });
        }
    },
    async deleteAddress({ commit, rootState }, addressId) {
        const resp = await this.$api.post("address/delete?" + getAddressQuery(rootState.delivery.city?.id, true), {
            address_id: addressId,
        });
        if (resp.success === true) {
            commit("deleteAddress", addressId);
        }
    },
    async editAddressSave({ commit, rootState }, address) {
        const saveAddress = { ...address };
        // saveAddress['comment'] = rootState.delivery.deliveryAddressComment;

        try {
            const resp = await this.$api.post(
                "address/edit?" + getAddressQuery(rootState.delivery.city?.id, true),
                saveAddress
            );

            if (resp.success !== true) {
                return Promise.reject(resp);
            }

            if (rootState.delivery.city && saveAddress.city == rootState.delivery.city.name) {
                saveAddress.city = null;
            }

            commit("setEditAddressId", null);
            commit("updateAddress", saveAddress);

            // dispatch('loadAddresses');

            return saveAddress;
        } catch (error) {
            console.warn("Ошибка редактирования заказа");
            console.error(error);
            return Promise.reject({ [error.error_code]: error.error_message });
        }
    },
    validateAddress({ commit, dispatch }, addressData) {
        if (!addressData.city) {
            return Promise.reject({ delivery_address_city: "Заполните город" });
        }

        let hasDeliveryError = false;
        const requiredFields = ["street", "house"];
        for (let i = 0; i < requiredFields.length; i++) {
            const field = requiredFields[i];
            if (!addressData[field] || String(addressData[field]).trim().length === 0) {
                hasDeliveryError = true;
            }
        }

        if (hasDeliveryError) {
            return Promise.reject({ delivery_address: "Заполните город, улицу и номер дома" });
        }

        if (!addressData.flat || String(addressData.flat).trim().length === 0) {
            return Promise.reject({ delivery_address_flat: "Заполните номер квартиры/офиса" });
        }
    },
    async validateBonusCard({ dispatch }, payload) {
        const errors = validateUserData(
            {
                phone: payload.phone,
                birthday: payload.birthday,
                complianceAgreement: payload.complianceAgreement,
                bonusAgreement: payload.bonusAgreement,
                confirmType: payload.confirmType,
            },
            "bonus_card."
        );

        if (Object.keys(errors).length === 0) {
            try {
                const res = await this.$api.post("user/validateBonusCard", {
                    phone: payload.phone,
                    name: payload.name,
                    birthdate: payload.birthday,
                });
                dispatch("errors/clearError", "bonus_card", { root: true });
            } catch ({ error_code, error_message }) {
                const errorCode =
                    error_code == "invalid_phone" || error_code == "invalid_email" || error_code == "has_bonus_card"
                        ? error_code.replace("invalid_", "")
                        : "save";

                dispatch(
                    "errors/addDisplayError",
                    { errors: { ["bonus_card." + errorCode]: error_message } },
                    { root: true }
                );
            }
        } else {
            dispatch("errors/addDisplayError", { errors }, { root: true });
        }
    },
    async addBonusCard({ commit, dispatch, rootGetters }, payload) {
        dispatch("errors/clearDisplayError", "bonus_card", { root: true });

        const errors = validateUserData(
            {
                ...(payload.name ? { fio: payload.name } : {}),
                phone: payload.phone,
                ...(payload.email ? { email: payload.email } : {}),
                birthday: payload.birthday,
                complianceAgreement: payload.complianceAgreement,
                bonusAgreement: payload.bonusAgreement,
            },
            "bonus_card."
        );

        if (Object.keys(errors).length === 0) {
            try {
                const resp = await this.$api.post("user/addBonusCard", {
                    name: payload.name || "",
                    ...(payload.name ? { email: payload.email } : {}),
                    phone: payload.phone,
                    birthdate: payload.birthday,
                    ext: payload.ext,
                });

                if (!payload.ext) {
                    // sendYandexMetric('sms-start');
                    // sendYandexMetric('sms-end');
                }
                commit("form/setHasBonusCard", true, { root: true });
                if (rootGetters["auth/isLoggedIn"] && rootGetters["form/getPhone"] == payload.phone) {
                    dispatch("fetchProfile").then((error) => {
                        console.warn("Ошибка получения профиля");
                        console.error(error);
                    });
                } else {
                    dispatch(
                        "errors/addDisplayError",
                        {
                            errors: { "bonus_card.complete_save": "ok" },
                            timeout: 20 * 1000,
                        },
                        { root: true }
                    );
                }
            } catch (error) {
                console.warn("Ошибка добавления бонусной карты");
                console.error(error);

                const errorCode =
                    error.error_code == "invalid_phone" ||
                    error.error_code == "invalid_email" ||
                    error.error_code == "has_bonus_card"
                        ? error.error_code.replace("invalid_", "")
                        : "save";

                dispatch(
                    "errors/addDisplayError",
                    { errors: { ["bonus_card." + errorCode]: error.error_message } },
                    { root: true }
                );

                return Promise.reject(error.error_message);
            }
        } else {
            dispatch("errors/addDisplayError", { errors }, { root: true });

            return Promise.reject("Add bonus card failed");
        }
    },

    async showUserAgreement({ dispatch }, id: number) {
        const resp: InfoPages = await this.$api.get(`/info/page/${id}`);
        if (!resp || !resp.title) {
            return;
        }

        await dispatch(
            "modals/openModal",
            {
                modalName: "UserAgreements",
                modalData: {
                    title: resp.title,
                    content: resp.content,
                },
                componentProps: { size: "xl" },
                lastPopup: true,
            },
            { root: true }
        );
    },

    async showBonusCardAgreement({ dispatch }) {
        await dispatch("showUserAgreement", 1);
    },
    async showPersonalDataProcessingAgreement({ dispatch }) {
        await dispatch("showUserAgreement", 2);
    },
    async showPublicOfferCert({ dispatch }) {
        await dispatch("showUserAgreement", 3);
    },
    async showRuleGiftCert({ dispatch }) {
        await dispatch("showUserAgreement", 4);
    },
    async setDefaultCard({ commit }, cardId) {
        commit("setDefaultCardId", cardId);
        await this.$api
            .post("/user/setDefaultCardId", { id: cardId })
            .then((resp) => {
                console.log(resp);
            })
            .catch(({ error_message }) => {
                alert("Не удалось установить карту по умолчанию");
            });
    },
    deleteCard({ commit, dispatch }, cardId) {
        this.$api
            .post("/user/deleteCardToken", { id: cardId })
            .then((resp) => {
                commit("deleteCardToken", cardId);
            })
            .catch(({ error_message }) => {
                alert(error_message);
            });
    },
    async afterLogin({ dispatch, rootGetters }, payload) {
        if (this.$router.currentRoute.name === "certs" || this.$router.currentRoute.name === "bonus_card") {
            // На странице сертификата сделаем подгрузку данных на месте
            const promise = [dispatch("fetchProfile"), dispatch("cart/loadCart", null, { root: true })];

            if (this.$router.currentRoute.name === "bonus_card") {
                const profile = rootGetters["account/profile"];
                if (profile && profile.bonus_card) {
                    promise.push(this.$router.push("/user/bonus_card"));
                }
            }

            await Promise.all(promise).catch((error) => {
                console.warn("Ошибка получения корзины");
                console.error(error);
            });
        } else {
            console.log("reload");
            location.reload();
        }
    },

    //
    //  favoriteProduct({commit, getters}, data) {
    //     let currentFavorites = [...getters.favoriteProducts];
    //     let newFavorites = [...getters.favoriteProducts];
    //     if(data['mark']) {
    //         newFavorites.push(data['product'])
    //     }else {
    //         newFavorites = newFavorites.filter(o => o != data['product']);
    //     }
    //     commit('setFavoriteProduct', newFavorites);
    //
    //     api.post('user/favoriteProduct', {
    //         product: data['product'],
    //         mark: data['mark'],
    //     }).then((resp) => {
    //         commit('setFavoriteProduct', resp['products']);
    //     }).catch(() => {
    //         commit('setFavoriteProduct', currentFavorites);
    //     });
    // },
    // favorite({getters, dispatch, rootGetters}, id) {
    //     if (!rootGetters['auth/isLoggedIn']) {
    //         $("#js-auth-popup").modal('show');
    //         return false;
    //     }
    //     dispatch('favoriteProduct', {
    //         product: id,
    //         mark: !getters.isProductFavorite(id),
    //     });
    //
    // },
};
