import { render, staticRenderFns } from "./MenuPopup.vue?vue&type=template&id=066e5e1d&scoped=true&"
import script from "./MenuPopup.vue?vue&type=script&lang=js&"
export * from "./MenuPopup.vue?vue&type=script&lang=js&"
import style0 from "./MenuPopup.vue?vue&type=style&index=0&id=066e5e1d&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "066e5e1d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/usr/src/app/components/icons/Logo.vue').default,NuxtCityLink: require('/usr/src/app/components/common/NuxtCityLink.vue').default,BurgerClose: require('/usr/src/app/components/icons/BurgerClose.vue').default,AuthBlock: require('/usr/src/app/components/AuthBlock.vue').default,ConnectInfo: require('/usr/src/app/components/ConnectInfo.vue').default,MenuDelimeter: require('/usr/src/app/components/MenuDelimeter.vue').default,CameraLive: require('/usr/src/app/components/CameraLive.vue').default,FeedbackInfo: require('/usr/src/app/components/FeedbackInfo.vue').default,MenuIconTile: require('/usr/src/app/components/icons/MenuIconTile.vue').default,MenuIconList: require('/usr/src/app/components/icons/MenuIconList.vue').default,Toggler: require('/usr/src/app/components/order/tabs/Toggler.vue').default,GoogleStore: require('/usr/src/app/components/icons/GoogleStore.vue').default,AppleStore: require('/usr/src/app/components/icons/AppleStore.vue').default,NavSoc: require('/usr/src/app/components/sections/NavSoc.vue').default})
